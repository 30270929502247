import { TemplateViewer } from "./template-viewer";
import { Viewer } from "./viewer";
import { PDFViewer } from "./pdf/PDFViewer";
import { useState } from "react";

export const ViewerRenderer = () => {
  const [path, id] = window.location.pathname.substring(1).split("/");
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("wglang") || "");
  const changeLanguageCallback = (languageCode) => {
    setCurrentLanguage(languageCode); // This will intentionally trigger a re-render of the Viewer component because we are passing the currentLanguage as a prop to it.
    // Sometimes it works without a re-render, but the interaction between WeGlot's script, and the react app occasionally won't register the language switch till a reload.
  }

  switch (path) {
    case "brite-template-preview":
      return id ? <TemplateViewer templateId={id} /> : null;
    case "pdf-preview":
      return id ? <PDFViewer guideId={id} /> : null;
    default:
      return <Viewer key={currentLanguage} changeLanguageCallback={changeLanguageCallback}/>;
  }
};
